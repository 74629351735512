import React, { useEffect, useState } from 'react';
import {Select , Divider } from 'antd'; 
import { useRouter } from 'next/router';

const { Option } = Select;

const TopbarLanguage = () => {

    const router =useRouter();
    const { locale } = router;
    const changeLanguage = (value) => {
        const locale = value;
        router.push(router.pathname, router.asPath,{ locale });
    };


    return (
        <div>
            <Select defaultValue={locale} style={{ width: 120, }} onChange={changeLanguage} >
                <Option value="en"><img src="https://img.icons8.com/color/30/000000/liberia.png"/> English </Option>
                <Option value="tr"><img src="https://img.icons8.com/color/30/000000/turkey.png"/> Turkish </Option>
            </Select>
        </div>
    );
};

export default TopbarLanguage;