import { combineReducers } from 'redux';
import Auth from './auth/reducer'; 
import App from './app/reducer'; 
import ThemeSwitcher from './themeSwitcher/reducer';

let rootReducer = combineReducers({ 
    Auth,
    App,
    ThemeSwitcher
});

export default rootReducer;