import { NextSeo } from "next-seo";
import Head from "next/head";

const NextSeoElements = () => {
  return (
    <>
      <Head>
        <title>Salih Ekinci</title>
        {/* <meta
          property="og:image"
          itemProp="image"
          content={process.env.NEXT_PUBLIC_WEB + "/assets/img/sonlogo3.png"}
        /> */}
        <link title="Salih Ekinci" rel="icon" href="/asdasd.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@site" />
        <meta name="twitter:creator" content="@handle" />
        {/* Websayfasını Hazırlayan hakkında iletişim bilgilerinin belirtilmesini sağlar. */}
        <meta name="Author" content="Salih Ekinci, info@salihekinci.com" />
        {/* Tüm sayfanın kısa özetinin belirtilmesini sağlar. */}
        <meta
          name="Abstract"
          content="05 Şubat 1982 yılında Şanlıurfa İli Viranşehir İlçesinde doğdu. İlk ve Orta öğrenimini Viranşehir İlçesinde tamamladı. Şanlıurfa Lisesini bitirdi. 2001 yılında Çukurova Üniversitesi Ziraat Fakültesi Bitkisel Üretim Bölümüne kayıt yaptırıp, Ocak 2006 yılında bitirdi. 2006 yılından itibaren Şanlıurfa İli Viranşehir İlçesinde; Ziraat Mühendisi olarak Zirai İlaç Bayiliği yapmaktadır. Aralık 2017 yılında TMMOB Ziraat Mühendisleri Odası Yönetim Kurulu Üyesi olarak seçilmiştir. Viranşehir Ziraat Mühendisleri Derneği Kurucu Üyesidir. 2010 yılından itibaren AK Parti Viranşehir İlçe Başkanlığı Üyeliği devam etmektedir. Orta düzeyde İngilizce ve Arapça, İyi düzeyde Kürtçe bilmektedir. Evli ve iki çocuk babasıdır. "
        />
        {/* Sitenizin varsayılan Metin dilini belirten etiket.*/}
        <meta httpEquiv="content-language" content="tr" />
        {/* sayfanızdaki Türkçe karakterlerin düzgün görünmesini sağlar.. */}
        {/* <meta httpEquiv="Content-Type" content="text/html; charset=iso-8859" /> */}
        {/* sayfanızdaki Türkçe karakterlerin düzgün görünmesini sağlar.. */}
        <meta httpEquiv="content-type" content="text/html charset=utf-8" />
        {/* Websitesinin telif hakları konusunda bilgi veren etikettir. */}
        <meta name="Copyright" content="Salih Ekinci" />
        {/* Web sayfanın içeriğini tanımlayacak virgül ile ayrılmış anahtar kelimeleri belirtir. */}
        <meta
          data-react-helmet="true"
          name="keywords"
          content="Salih Ekinci,Viranşehir,Viranşehir belediye başkanı,Salih ekinci başkan,salih,salih ekinci,viranşehir belediyesi,virbel,virbel viranşehir"
        />
        {/* Web sayfayı yayınlayan uygulamanın isminin ve sürümünün belirtilmesini sağlar. */}
        <meta name="publisher" content="2023" />
        {/* Arama motorlarına Websitesinin kaç Günde Güncellendiğini belirten Etiket */}
        <meta name="revisit-after" content="1" />
        {/* Web sayfa hakkında e-posta adresi belirtilmesini sağlar. */}
        <meta name="reply-to" content="info@salihekinci.com" />
        {/* Belirtilen süre sonunda belirtilen bir URL adresinin yüklenmesini sağlar. URL adresi belirtilmezse güncel sayfa adresi tekrar yüklenir. */}
        <meta name="refresh" content="5" />
        <meta name="robots" content="all" />
      </Head>
      <NextSeo
        title="Salih Ekinci"
        description="Her şey Viranşehir İçin . Bizler bugünlerden yarınları imar etmenin sorumluluğunu omuzlarımızda taşıyor. ve bu sorumlulukla gece gündüz demeden çalışmaya ..."
        canonical={`${
          process.env.NEXT_PUBLIC_WEB ?? "https://deneme.pigmentsoft.com.tr"
        }`}
        openGraph={{
          type: "website",
          locale: "tr_TR",
          url: `${process.env.NEXT_PUBLIC_WEB ?? "https://salihekinci.com"}`,
          site_name: "Salih Ekinci",
        }}
      />
    </>
  );
};

export default NextSeoElements;
