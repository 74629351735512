import { LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT ,STATELOADING} from './actions';

const initState = {
    user: '',
    isAuthenticated: false,
    error: false,
    errorMessage: '',
    stateloading:false
}

const authReducer = (state = initState, action) => {

    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.user,
                isAuthenticated: true,
                error: false,
                errorMessage: ''
            };
        case LOGIN_ERROR:
            return {
                ...state,
                user: '',
                error: true,
                isAuthenticated: false,
                errorMessage: action.error
            };
        case LOGOUT:
            return {
                user: '',
            };
        case STATELOADING:
            return {
                ...state,
                stateloading:action.state
            }    
        default:
            return state;
    }
}

export default authReducer;