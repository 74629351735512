import {
  AppstoreOutlined,
  MailOutlined,
  CopyOutlined,
  HomeOutlined,
  SettingOutlined,
  UserOutlined,
  FileImageOutlined,
  UserSwitchOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
const options = [
  {
    key: "manset",
    label: "Manşetler",
    leftIcon: <FileImageOutlined />,
  },
  {
    key: "icerikler",
    label: "İçerikler",
    leftIcon: <CopyOutlined />,
  },
  // {
  //   key: "kategori",
  //   label: "Kategoriler",
  //   leftIcon: <AppstoreOutlined />,
  // },
  {
    key: "mesajlar",
    label: "E-posta",
    leftIcon: <MailOutlined />,
    children: [
      // {
      //   key: "e-posta/cevapla",
      //   label: "Yeni mesaj",
      // },
      {
        key: "e-posta/gelen-mesaj",
        label: "Gelen mesaj",
      },
      {
        key: "e-posta/giden-kutusu",
        label: "Gönderilenler",
      },
    ],
  },
  {
    key: "kullaniciler",
    label: "Kullanıcılar",
    leftIcon: <UsergroupDeleteOutlined />,
  },
  {
    key: "ayarlar",
    label: "Ayarlar",
    leftIcon: <SettingOutlined />,
  },
  // {
  //   key: "kisiler",
  //   label: "Kişiler",
  //   leftIcon: <UserSwitchOutlined />,
  // },
  {
    key: "logs",
    label: "Log Kayıtları",
    leftIcon: <CopyOutlined />,
  },
];

export default options;
