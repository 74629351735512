import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius } from '../../../library/helpers/style_utils';

const SidebarWrapper = styled.div`
  .ant-layout-sider-collapsed{
    flex: 0 0 42px!important;
    max-width: 42px!important;
    min-width: 42px!important;
  }
  
  .isomorphicSidebar {
    z-index: 1000;
    background:#2d3446;
    width: 2 20px;
    height: 100%;
    flex: 0 0 2 20px;
    .scrollarea {
      height: calc(100vh - 70px);
    }


    @media only screen and (max-width: 767px) {
      width: 240px !important;
      flex: 0 0 240px !important;
    }

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    .isoLogoWrapper {
      height: 60px;
      background: #fff;
      border-right : 1px solid #2d344614;
      border-bottom: 1px solid #2d344614;
      margin: 0;
      padding: 0 10px;
      text-align: center;
      overflow: hidden;
      ${borderRadius()};

      h3 {
        a {
          font-size: 15px;
          font-weight: 100;
          line-height: 60px;
          letter-spacing: 3px;
          text-transform: uppercase;
          color: black;
          display: block;
          text-decoration: none;
          img{
            height : 42px;
          }
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .isoLogoWrapper {
        padding: 0;
        h3 {
          a {
            font-size: 0px;
            font-weight: 200;
            letter-spacing: 0;
            img{
              height : 30px;
            }
            
          }
        }
      }

      .ant-menu-item-selected .ant-menu-title-content{
        position: relative;
        left: -5px;
      }
    }

    .isoDashboardMenu {
      padding-top: 25px !important;
      padding-bottom: 35px !important;
      background: transparent;


      a {
        text-decoration: none;
        font-weight: bold;
      }

      .ant-menu-item {
        width: 90%;
        display: -ms-flexbox;
        display: flex;
        border-radius: 0 25px 25px 0;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 14px!important;
        margin: 0;

      }

      .isoMenuHolder {
        display: flex;
        align-items: center;

        i {
          font-size: 19px;
          color: inherit;
          margin: ${(props) =>
          props['data-rtl'] === 'rtl' ? '0 0 0 10px' : '3px 10px 0 0'};
          width: 14px;
          color: #fff;
          ${transition()};
        }
      }

      .anticon {
        font-size: 14px;
        margin-right: 10px;
        color: inherit;
        ${transition()};
      }

      .nav-text {
        font-size: 13px;
        color: inherit;
        font-weight: 400;
        letter-spacing: 0.1px;
        ${transition()};
      }

      .ant-menu-item-selected {
        border-left: 5px solid #036576;
        background-color: rgba(117 ,193, 195 , 0.18) !important;
        .anticon {
          color: #05bbc8;
        }

        i {
          color: #e6e6fe;
        }

        .nav-text {
          color: fff;
        }
      }

      > li {
        &:hover {
          i,
          .nav-text {
            color: #58a0a2;
          }
        }
      }
    }

    .ant-menu-submenu-open > .ant-menu-submenu-title{
      background-color: rgba(117 ,193, 195 , 0.18) !important;
      width: 100%!important;
      border-radius:0!important;
    }
    .ant-menu-submenu-title{

      i{
        top : 55%;
      }
    }
    
    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: ${palette('secondary', 5)};
    }
    .ant-menu-dark li{
      // transtion: 0.8s all;
    }
    .ant-menu-dark li:hover{
      // background: #3a4e5c;
      // border-radius: 0 25px 25px 0;
      // border-left: 5px solid #036576;
    }
    .ant-menu-item:hover{
      color:#05bbc8 !important;
      .nav-text{
        color:#05bbc8!important;
      }
      .anticon{
        color:#05bbc8 !important;
      }
    }
    .ant-menu-submenu-inline,
    .ant-menu-submenu-vertical {
      > .ant-menu-submenu-title {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 14px!important;

        > span {
          display: flex;
          align-items: center;
        }

        .ant-menu-submenu-arrow {
          left: ${(props) => (props['data-rtl'] === 'rtl' ? '25px' : 'auto')};
          right: ${(props) => (props['data-rtl'] === 'rtl' ? 'auto' : '25px')};

          &:before,
          &:after {
            width: 8px;
            ${transition()};
          }

          &:before {
            transform: rotate(-45deg) translateX(3px);
          }

          &:after {
            transform: rotate(45deg) translateX(-3px);
          }

          ${'' /* &:after {
            content: '\f123';
            font-family: 'Ionicons' !important;
            font-size: 16px;
            color: inherit;
            left: ${props => (props['data-rtl'] === 'rtl' ? '16px' : 'auto')};
            right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '16px')};
            ${transition()};
          } */};
        }

        &:hover {
          .ant-menu-submenu-arrow {
            &:before,
            &:after {
              color: #ffffff;
            }
          }
        }
      }


      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          padding-left: ${(props) =>
            props['data-rtl'] === 'rtl' ? '0px !important' : '50px !important'};
          padding-right: ${(props) =>
            props['data-rtl'] === 'rtl' ? '50px !important' : '0px !important'};
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          color: inherit;
          ${transition()};

          &:hover {
            a {
              color: #ffffff !important;
            }
          }
        }

        .ant-menu-item-group {
          padding-left: 0;

          .ant-menu-item-group-title {
            padding-left: 100px !important;
          }
          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 125px !important;

            }
          }
        }
      }

      .ant-menu-sub {
        box-shadow: none;
        background-color: transparent !important;
      }
    }

    &.ant-layout-sider-collapsed {
      .nav-text {
        display: none;
      }

      .ant-menu-submenu-inline > {
        .ant-menu-submenu-title:after {
          display: none;
        }
      }

      .ant-menu-submenu-vertical {
        > .ant-menu-submenu-title:after {
          display: none;
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            height: 35px;
          }
        }
      }
    }
  }
  .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span, .ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: #fff;
}
`;

export default SidebarWrapper;
