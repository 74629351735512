import styled from 'styled-components';
import { palette } from 'styled-theme';
import {
  transition,
  borderRadius,
  boxShadow,
} from 'library/helpers/style_utils'; 

const TopbarDropdownWrapper = styled.div`

.isoDropdownHeader {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 20px;
  padding: 10px 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 14px;
    font-weight: 500;
    color: ${palette('text', 0)};
    text-align: center;
    text-transform: uppercase;
    margin: 0;
  }
}

.isoDropdowndiv{
  cursor: pointer;
  color:rgba(0, 0, 0, 0.45);

}
.isoDropdownLink{
  color:rgba(0, 0, 0, 0.45);
}
.notification {
  font-family: sans-serif;
  text-align: center;
}
.contentnotification {
  max-height: 17rem;
  max-width: 17rem;
  overflow: hidden;
  position: relative;
}
.ant-divider-horizontal{
  margin: 10px 0 !important;
}
.ant-popover-title{
  text-align: center;
  text-transform: uppercase;
}
.notificationitem:hover{
  background-color: red;
}

.isoViewAllBtn {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  padding: 2px;
  color: #05bbc8;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #05bbc8;
  background:#05bbc8;
  border-radius: 18px;
  margin-bottom: 5px;
  color: #fff;
  transition: 1.1s all !important;
  &:hover {
    background:#04a9b5;
  }
}

`;

export const Notifications =styled.div`
   .isoIconWrapper svg{
        transition: 0.7s ease-in-out;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        outline: 0px;
        border: 0px;
        margin: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        text-align: center;
        flex: 0 0 auto;
        font-size: 1.5rem;
        padding: 8px;
        border-radius: 50%;
        overflow: visible;
        color: rgb(99, 115, 129);
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        width: 40px;
        height: 40px;
        &:hover{
          transform: scale(1.09);
          background-color: rgba(99, 115, 129, 0.08);
        }
   }
`;
export default TopbarDropdownWrapper;
