
import React from 'react';
import 'antd/dist/antd.css';

import { Button, Result } from 'antd';

const Error404 = () => {
    return (

            <div >
                <Result
                    status="404"
                    title="404"
                    subTitle="üzgünüz, erişmek istediğiniz sayfa bulunamadı "
                    extra={<Button type="primary">Back Home</Button>}
                />

            </div>
    );
}

export default Error404;



