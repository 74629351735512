import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout } from "antd";
import appActions from "../../../redux/app/actions";
import TopbarNotification from "./TopbarNotification";
import TopbarMessage from "./TopbarMessage";
import TopbarSearch from "./TopbarSearch";
import TopbarUser from "./TopbarUser";
import TopbarWrapper from "./Topbar.styles";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { IoArrowDownCircleOutline } from "react-icons/io";
import { TiArrowForward } from "react-icons/ti";
import TopbarLanguage from "./TopbarLanguage";
import Link from "next/link";
import { useRouter } from "next/router";
const { Header } = Layout;
const { toggleCollapsed } = appActions;

export default function Topbar() {
  const [selectedItem, setSelectedItem] = React.useState("");
  //const customizedTheme = useSelector(state => state.ThemeSwitcher.topbarTheme);
  const customizedTheme = {
    backgroundColor: "#fff",
    textColor: "#444",
  };
  const { collapsed, openDrawer } = useSelector((state) => state.App);
  const dispatch = useDispatch();
  const handleToggle = React.useCallback(
    () => dispatch(toggleCollapsed()),
    [dispatch]
  );
  const isCollapsed = collapsed && !openDrawer;
  const styling = {
    background: customizedTheme.backgroundColor,
    position: "fixed",
    width: "100%",
    height: 60,
  };

  const router = useRouter();
  return (
    <TopbarWrapper>
      <Header
        style={styling}
        className={
          isCollapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
        }
      >
        <div className="isoLeft">
          <button
            style={{
              color: customizedTheme.textColor,
              border: "none",
              background: "none",
              fontSize: "20px",
            }}
            onClick={handleToggle}
          >
            {collapsed ? <MenuOutlined /> : <MenuOutlined />}
          </button>
        </div>

        <ul className="isoRight">
          {/* <li className="isoSearch">
            <TopbarSearch />
          </li> */}

          {/* <li>
            <TopbarLanguage />
          </li> */}

          <li className="siteye">
            <Link href="/">
              <a target="_blank">
                <TiArrowForward
                  style={{ marginRight: "5px", fontSize: "1.25rem" }}
                />
                Siteye Git
              </a>
            </Link>
          </li>

          {/* <li onClick={() => setSelectedItem('notification')} className={selectedItem ? 'isoNotify active' : 'isoNotify'} >
            <TopbarNotification />
          </li> */}

          {/* <li onClick={() => setSelectedItem('message')} className="isoMsg">
            <TopbarMessage />
          </li> */}

          <li onClick={() => setSelectedItem("user")} className="isoUser">
            <TopbarUser />
          </li>
        </ul>
      </Header>
    </TopbarWrapper>
  );
}
