import React from "react";
import Image from "next/image";
import Link from "next/link";
import { Col, Container, Row } from "react-bootstrap";
import { TiSocialFacebook } from "react-icons/ti";
import { SiInstagram, SiTwitter } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import baskan from "../../../public/baskan-img/Group 163.png";
import {
  RiFacebookFill,
  RiInstagramLine,
  RiTwitterFill,
  RiYoutubeLine,
} from "react-icons/ri";
const Fotter = () => {
  return (
    <div id="fotter">
      <div className="fotter">
        <div className="sosyalmedya-hesaplar">
          <Container>
            <Row>
              <Col xl={6} lg={4} md={3} xs={5} className="viransheir-logo">
                <Image
                  src={"/baskan-img/viransehirlogo1.png"}
                  alt="dddd"
                  width={160}
                  height={45}
                />
              </Col>
              <Col xl={6} lg={8} md={9} xs={7} className="sosyal-media-icon">
                <ul className="icon-list">
                  <li>
                    <Link href="https://www.facebook.com/salihekinciviransehir">
                      <a target="_blank" className=" facebook">
                        <span className="icon-list-background">
                          <RiFacebookFill className="icon " />
                        </span>
                        <span className="icon-span"> FACEBOOK</span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="https://www.twitter.com/salih_ekinci">
                      <a target="_blank" className="twitter">
                        <span className="icon-list-background">
                          <RiTwitterFill className="icon" twitter />
                        </span>
                        <span className="icon-span"> TWITTER</span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="https://www.instagram.com/salih_ekinci">
                      <a target="_blank" className="instegram">
                        <span className="icon-list-background">
                          <RiInstagramLine className="icon" />
                        </span>
                        <span className="icon-span"> INSTAGRAM</span>
                      </a>
                    </Link>
                  </li>
                  {/* <li>
                    <Link href="#">
                      <a>
                        <span className="icon-list-background youtube">
                          {" "}
                          <RiYoutubeLine className="icon " />
                        </span>
                        <span className="icon-span"> YOUTUBE</span>
                      </a>
                    </Link>
                  </li> */}
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="baskan">
          <Container>
            <Row>
              <Col lg={6} md={5} xs={12}>
                <div className="baskan-bilgileri">
                  <Image
                    src={baskan}
                    alt="Picture of the author"
                    layout="responsive"
                  />
                </div>
              </Col>
              <Col lg={6} md={7} xs={12}>
                <div className="navbar">
                  <Navbar>
                    <Container>
                      <Navbar.Toggle aria-controls="basic-navbar-nav" />

                      <Navbar.Collapse>
                        <Nav className="d-flex list">
                          <Nav.Item>
                            <Link href="/">
                              <a>
                                <span>Ana Sayfa</span>
                              </a>
                            </Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Link href="/#baskan-hakkinda">
                              <a>
                                <span>Hakkımda</span>
                              </a>
                            </Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Link href="/#haberler">
                              <a>
                                <span>Haberler</span>
                              </a>
                            </Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Link href="/#foto-galeri">
                              <a>
                                <span>Galeri</span>
                              </a>
                            </Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Link href="/#ulasim">
                              <a>
                                <span>İletişim</span>
                              </a>
                            </Link>
                          </Nav.Item>
                        </Nav>
                      </Navbar.Collapse>
                    </Container>
                  </Navbar>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Fotter;
