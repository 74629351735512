import defaultTheme from './default';

const custom = {
  ...defaultTheme,
  palette: {
    primary: ['#f00'],
    secondary: ['#0f0'],
  },
};

export default custom;