import React, { useRef, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "next/image";
import Link from "next/link";
import baskan from "../../../public/baskan-img/Salih Ekinci logo.png";
import baskanBlack from "../../../public/baskan-img/Salih 1 Ekinci logo.png";
import { FaFacebookF } from "react-icons/fa";
import { SiInstagram, SiTwitter } from "react-icons/si";
import Header from "components/site/header/header";

const NavbarCustum = ({ pageProps }) => {
  const ref = useRef();
  const ref1 = useRef();
  const [pageWidth, setPageWidth] = useState();
  function useOutsideAlerter(ref, ref1) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (ref1.current.classList[2]) {
            ref1.current.classList?.remove("show");
            document.getElementById("button-click")?.click();
          }
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, ref1]);
  }
  useEffect(() => {
    setPageWidth(window.innerWidth);
  }, []);
  useOutsideAlerter(ref, ref1);
  return (
    <div id="baskan-navbar" className={pageProps?.indexPage ? "home" : "detay"}>
      {/* {pageProps?.indexPage ? (
        <div className="background"></div>
      ) : (
        <div style={{ padding: "0px 0px 0px 0px" }}></div>
      )} */}
      <div className="baskan-navbar">
        <Navbar expand="lg">
          <Container>
            <Link href="/">
              <div className="logo">
                <Navbar.Brand href="#">
                  {pageWidth <= 768 || !pageProps?.indexPage ? (
                    <Image
                      src={baskanBlack}
                      alt="Picture of the author"
                      layout="responsive"
                    />
                  ) : (
                    <Image
                      src={baskan}
                      alt="Picture of the author"
                      layout="responsive"
                    />
                  )}

                  <p>Viranşehir Belediye Başkanı</p>
                </Navbar.Brand>
              </div>
            </Link>
            <Navbar.Toggle
              ref={ref}
              id="button-click"
              aria-controls="basic-navbar-nav"
            />
            <Navbar.Collapse ref={ref1} id="basic-navbar-nav   nav-item">
              <Nav className="d-flex list">
                <Nav.Item>
                  <Link href="/">
                    <a>
                      <span>Ana Sayfa</span>
                    </a>
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link href="/#baskan-hakkinda">
                    <a>
                      <span>Hakkımda</span>
                    </a>
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link href="/#haberler">
                    <a>
                      <span>Haberler</span>
                    </a>
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link href="/#foto-galeri">
                    <a>
                      <span>Galeri</span>
                    </a>
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link href="/#ulasim">
                    <a>
                      <span>İletişim</span>
                    </a>
                  </Link>
                </Nav.Item>
                <div className="btn">
                  <Link href="https://www.viransehir.bel.tr" className="viran">
                    <a target="_blank">VİRANŞEHİR'E GİT</a>
                  </Link>
                </div>
                <ul className="icon-list">
                  <li className="facebook">
                    <Link href="https://www.facebook.com/salihekinciviransehir">
                      <a target="_blank" className="icon-list-background">
                        <FaFacebookF className="icon" />
                      </a>
                    </Link>
                  </li>
                  <li className="twitter">
                    <Link href="https://www.twitter.com/salih_ekinci">
                      <a target="_blank" className="icon-list-background">
                        <SiTwitter className="icon" />
                      </a>
                    </Link>
                  </li>
                  <li className="instegram">
                    <Link
                      href="https://www.instagram.com/salih_ekinci"
                      className="icon-list-background"
                    >
                      <a target="_blank" className="icon-list-background">
                        <SiInstagram className="icon" />
                      </a>
                    </Link>
                  </li>
                </ul>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      {pageProps?.indexPage ? <Header pageProps={pageProps} /> : ""}
    </div>
  );
};

export default NavbarCustum;
