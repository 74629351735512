import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout } from "antd";
import useWindowSize from "../../library/hooks/useWindowSize";
import appActions from "../../redux/app/actions";
import Sidebar from "components/common/sidebar/Sidebar";
import Topbar from "components/common/topbar/Topbar";
import { DashboardContainer, DashboardGlobalStyles } from "./Dashboard.styles";
import { useRouter } from "next/router";
const { Content, Footer } = Layout;

const { toggleAll } = appActions;

const styles = {
  layout: {
    flexDirection: "row",
    overflowX: "hidden",
  },
  content: {
    padding: "60px 0 0",
    flexShrink: "0",
    background: "#f1f8fb",
    position: "relative",
  },
  footer: {
    background: "#ffffff",
    textAlign: "center",
    borderTop: "1px solid #ededed",
  },
};

function AdminLayout({ children }) {
  const dispatch = useDispatch();
  const appHeight = useSelector((state) => state.App.height);
  const { width, height } = useWindowSize();
  const router = useRouter();
  const [user, setUser] = useState(null);
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    dispatch(toggleAll(width, height));
  }, [width, height, dispatch]);

  useEffect(() => {
    authCheck(router.asPath);
    const hideContent = () => setAuthorized(false);
    router.events.on("routeChangeStart", hideContent);
    router.events.on("routeChangeComplete", authCheck);
    return () => {
      router.events.off("routeChangeStart", hideContent);
      router.events.off("routeChangeComplete", authCheck);
    };
  }, []);

  function authCheck(url) {
    setUser(true);
    const publicPaths = ["/account/login", "/account/register"];
    const path = url.split("?")[0];
    if (
      !window.localStorage.getItem(process.env.NEXT_PUBLIC_TOKEN_NAME) &&
      !window.localStorage.getItem(process.env.NEXT_PUBLIC_TOKEN_NAME)?.length
    ) {
      setAuthorized(false);
      router.push({
        pathname: "/login",
      });
    } else {
      setAuthorized(true);
    }
  }

  return !authorized ? (
    ""
  ) : (
    <DashboardContainer>
      <DashboardGlobalStyles />
      <Layout style={{ height: height }}>
        <Topbar />
        <Layout style={styles.layout}>
          <Sidebar />
          <Layout
            className="isoContentMainLayout"
            style={{ height: appHeight }}
          >
            <Content
              id="site-content"
              className="isomorphicContent"
              style={styles.content}
            >
              {children}
            </Content>
            <Footer style={styles.footer}>
              {" "}
              @Created by PigmentSoft -{new Date().getFullYear()}{" "}
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </DashboardContainer>
  );
}
export default AdminLayout;
