import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "next/image";
import viran from "../../../public/baskan-img/tek sevda viransehir.png";
import baskan from "../../../public/baskan-img/asdasd.png";
import { FaMousePointer } from "react-icons/fa";
import { Button } from "antd";
import Link from "next/link";
import Slider from "react-slick";
import imgLoader from "utils/nextImageLoader";

const Header = ({ pageProps }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const { mansetsFotograflar } = pageProps;
  console.info("mansetsFotograflar : ", mansetsFotograflar.data);
  return (
    <>
      <div id="baskan-header">
        <Slider {...settings} className="haberler-slider">
          {mansetsFotograflar &&
            mansetsFotograflar?.data?.map((item, index) => {
              return (
                <div className="baskan-header">
                  <div className="img-baskan">
                    <Image
                      loader={imgLoader}
                      src={`model=Banner&fileName=${item.imageName}&imageSize=50`}
                      alt={"tek sevdiğimiz viran şehir"}
                      layout="fill"
                      className="image"
                    />
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </>
  );
};
export default Header;
