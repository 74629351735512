import React, { useEffect, useState } from "react";
import Link from "next/link";
import Popover from "./../../uielements/popover";
import TopbarDropdownWrapper from "./TopbarDropdown.styles";
import { FiLogOut } from "react-icons/fi";
import { BiUser } from "react-icons/bi";
import { logout } from "utils/services";
import { getData } from "utils/fetchData";
import Image from "next/image";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";

export default function TopbarUser() {
  const [visible, setVisibility] = React.useState(false);
  function handleVisibleChange() {
    setVisibility((visible) => !visible);
  }
  const [img, setImage] = useState();
  const userimage = process.env.NEXT_PUBLIC_USER_IMAGE;

  const content = (
    <TopbarDropdownWrapper className="isoUserDropdown">
      <Link href="/admin/my-profile" className="isoDropdownLink">
        <a className="isoDropdownLink">
          <BiUser style={{ marginRight: "5px", fontSize: "15px" }} />
          Profil Ayarları
        </a>
      </Link>
      <hr className="hrprofil" />

      <div className="isoDropdowndiv" onClick={logout}>
        <FiLogOut style={{ marginRight: "5px", fontSize: "15px" }} /> Çıkış Yap
      </div>
    </TopbarDropdownWrapper>
  );

  useEffect(() => {
    localStorage.getItem(userimage) &&
      setImage(localStorage.getItem(userimage));
    // let id =window.localStorage.getItem(process.env.NEXT_PUBLIC_USER_ID)
    // async function getResult(){
    //     await getData("/admin/GetUser/"+id+"",true).then(result =>{
    //         result?.imageBase64 && setImage(result?.imageBase64)
    //      });
    // }
    // id && getResult();
  }, []);

  return (
    <Popover
      content={content}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      arrowPointAtCenter={true}
      placement="bottomLeft"
    >
      <div className="isoImgWrapper">
        {img ? (
          <Image
            src={img}
            style={{ borderRadius: "20px" }}
            alt="Picture of the author"
            width={500}
            height={500}
          />
        ) : (
          <Avatar size={40} icon={<UserOutlined />} />
        )}

        {/* <img style={{borderRadius:"20px"}} alt="user" src={img}/> */}
        <span
          style={{ background: "#43d305" }}
          className="userActivity online"
        />
      </div>
    </Popover>
  );
}
