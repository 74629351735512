// import { authService } from '@app/services/authService';
// import authConfig from '../../config/authConfig';
// import { ErrorProviderCallout } from '../../services/hataYonetimi';
 
export const CHECK_AUTHORIZATION    = 'CHECK_AUTHORIZATION';
export const LOGIN_SUCCESS          = 'LOGIN_SUCCESS';
export const LOGIN_ERROR            = "LOGIN_ERROR";
export const CHECK_LOGIN            = "CHECK_LOGIN";
export const LOGOUT                 = "LOGOUT";
export const STATELOADING           ="STATElOADING";

const loginSuccess = user => {
    return {
        type: LOGIN_SUCCESS,
        user
    };
};

const loginError = error => {
    return {
        type: LOGIN_ERROR,
        error
    };
};

export const checkAuthorization = () => {
    const token = localStorage.getItem(authConfig.tokenLabel);
    if (token) {
        //is exp?
        return dispatch => {
            dispatch(loginSuccess(token))
        }
    }
    return dispatch => {
        dispatch(loginError(""))
    }
}
const isLoadinglogin = state => {
    return{
        type:STATELOADING,
        state
    }
}
export const login = (data) => {
    
    return dispatch => { 
        dispatch(isLoadinglogin(true))
        authService.login(data)        
            .then(result => {
                let data = result.data;
                

                
                if(data.status === false){
                    
                    // dispatch(loginSuccess(data));
                    dispatch(loginError(data.error));
                    return;
                }
                window.location.assign("/Admin")
            })
            .catch(err => {
                ErrorProviderCallout(err)
                // let status = err.response?.data ; 
                // let message = "";
                // status === 401 
                //     ?  message = status
                //     :  message = err.response.data.description
                // dispatch(loginError(message));
            }).finally(()=>{
                dispatch(isLoadinglogin(false))
            });
    }
}

export const logout = () => {
    authService.logout();
    return {
        type: LOGOUT,
    };
}