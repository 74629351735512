import Error404 from 'pages/404';
import React from 'react';
import Layout from './Layout';

const BaskanLayout = ({ children, pageProps }) => {
    return (
        <div>
            {
                pageProps.result.siteState ?
                    (<Layout children={children} pageProps={pageProps} />)
                    : (<Error404 />)
            }
        </div>
    );
}
export default BaskanLayout;
