import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "antd/dist/antd.css";
//import WebLayout from "../layout/web/index";
import BaskanLayout from "../layout/baskanLayout/index.jsx";
import AdminLayout from "../layout/admin/index";
import { useRouter } from "next/router";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "nprogress/nprogress.css";
import "../styles/globals.scss";
import Head from "next/head";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import laoder from "../library/helpers/loader.js";
import { Suspense, useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import tr_TR from "antd/lib/locale/tr_TR";
import "moment/locale/tr";
import dynamic from "next/dynamic";
import App from "next/app";
import { getData, postData } from "utils/fetchData";
import "../styles/globals.scss";
import NextSeoElements from "components/seo.js";
import { GoogleAnalytics } from "nextjs-google-analytics";
let admin = ["/admin"];

let authentication = [
  "/login",
  "/components",
  "/register",
  "/forgetPassword",
  "/ResetPassword",
  "/404",
];

const TopProgressBar = dynamic(
  () => {
    return import("components/container/TopProgressBar");
  },
  { ssr: false }
);

const LayoutChange = ({ children, pageProps, siteState }) => {
  const router = useRouter();
  const auth = authentication.filter((i) => i == router.pathname);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const loader = document.getElementById("scrennLoading");
      if (loader) {
        setTimeout(() => {
          loader.style.display = "none";
        }, 3000);
      }
    }
  }, []);

  if (router.pathname.includes(admin)) {
    return <AdminLayout>{children}</AdminLayout>;
  } else if (auth.length > 0) {
    return <>{children}</>;
  } else {
    return [
      <NextSeoElements />,
      <BaskanLayout pageProps={pageProps}>{children}</BaskanLayout>,
    ];
  }
};

export default function MyApp({ Component, pageProps }) {
  let siteState = pageProps.result?.siteState ?? true;

  useEffect(() => {
    // Hide splash screen shen we are server side
    import("bootstrap/dist/js/bootstrap");
  }, []);

  return (
    <Provider store={store}>
      <ConfigProvider locale={tr_TR}>
        <Head>
          <title>Salih Ekinci</title>
          <style>{laoder}</style>
          <link
            href="https://use.fontawesome.com/releases/v5.7.0/css/all.css"
            rel="stylesheet"
          />
        </Head>
        <LayoutChange pageProps={pageProps} siteState={siteState}>
          <TopProgressBar />
          <GoogleAnalytics trackPageViews />
          <Component {...pageProps} />
        </LayoutChange>
      </ConfigProvider>
    </Provider>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const res = await getData("/Site/GetSettings");
  if (appContext.Component.name == "Home" || appContext.ctx.pathname == "/") {
    let mansetsFotograflar = await postData(
      "/Site/GetMansets",
      { pageSize: 100, currentPage: 1 },
      false
    );
    return {
      pageProps: {
        ...appProps.pageProps,
        result: res,
        indexPage: true,
        mansetsFotograflar: mansetsFotograflar,
      },
    };
  }
  return {
    pageProps: {
      ...appProps.pageProps,
      result: res,
    },
  };
};
